<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Title"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-select
                  :items="categories"
                  v-model="selectedCategory"
                  label="Category"
                  outlined
                  item-text="title"
                  item-value="title"
                ></v-select>
              </v-col>
              <v-col cols="3" v-if="selectedCategory">
                <v-select
                  :items="subCategories"
                  v-model="selectedSubCategory"
                  label="Course"
                  outlined
                  item-text="title"
                  item-value="title"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="[{ title: 'Paid',status:'paid' }, { title: 'Not Paid',status:'not paid' }]"
                  v-model="paymentStatus"
                  label="Payment Status"
                  outlined
                  item-text="title"
                  item-value="status"
                ></v-select>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn class="submitbutton btn-dark" @click="filterVideos">
                  Filter {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col md="12">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Video Title</th>
                  <th class="text-left">Category</th>
                  <th class="text-left">Course</th>
                  <th>Content Type</th>
                  <th class="text-right">Duration</th>
                  <th class="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in allVideo.results" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.category }}</td>
                  <td>{{ item.subCategory }}</td>
                  <td>{{ item.videoType }}</td>
                  <td class="text-right">{{ item.duration }}</td>
                  <td class="text-right icons">
                    <router-link :to="`/dashboard/video/add/${item._id}`">
                      <v-icon class="icons-action">mdi-pencil</v-icon>
                    </router-link>
                    <v-icon class="icons-action" @click="deleteVideo(item._id)"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Video",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Video Listing",
      addlink: "/dashboard/video/add",
    },
    valid: false,
    title: undefined,

    status: true,
    limit: 10,
    page: 1,
    categories: [],
    subCategories: [],
    selectedCategory: undefined,
    selectedSubCategory: undefined,
    paymentStatus: undefined,
  }),
  methods: {
    validate() {},
    async filterVideos() {
      await this.fetchVideo({
        status: true,
        category: this.selectedCategory,
        subCategory: this.selectedSubCategory,
        search: this.title,
        videoType: this.paymentStatus,
        limit: this.limit,
        page: this.page,
      });
      await this.fetchVideoSubCategory({
        categoryType: "video",
        search: this.selectedCategory,
      });
      this.subCategories = this.allVideoSubCategory.results.list;
    },
    async deleteVideo(id) {
      if (!id) {
        console.error("No video ID provided for deletion");
        return;
      }

      if (confirm("Are you sure you want to delete this video?")) {
        try {
          const success = await this.deleteVideoAction(id);
          if (success) {
            this.allVideo.results = this.allVideo.results.filter(
              (video) => video._id !== id
            );
          } else {
            console.error("Failed to delete video");
          }
        } catch (error) {
          console.error("Error deleting video:", error);
        }
      }
    },

    ...mapActions([
      "fetchVideo",
      "fetchCategory",
      "fetchVideoSubCategory",
      "deleteVideoAction",
    ]),
  },
  computed: {
    ...mapGetters([
      "allVideo",
      "allLoadingCurd",
      "allCategory",
      "allVideoSubCategory",
    ]),
  },
  async created() {
    await this.fetchVideo({
      status: true,
      subCategory: this.selectedSubCategory,
      limit: this.limit,
      page: this.page,
    });
    await this.fetchCategory({ categoryType: "video" });

    this.categories = this.allCategory.results.list;
   
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.filtertitle {
  margin-bottom: 10px;
}
.title {
  font-size: 1.875rem !important;
  font-weight: bold;
  margin: 10px 0 0 10px;
}

.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}
.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.icons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2px;
}
</style>
